import { useState } from "react";
import "../../assets/scss/components/carousel.scss";
import { Container, Row, Col } from "react-bootstrap";
import { getAltLabel } from "../../helper/ImageFormatter";
import { CarouselProps } from "../../types/Carousel";
import { ImageData } from "../../types/Image";

function Carousel({ id, images }: CarouselProps) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [slides] = useState([...images.data]);

  function prevSlide() {
    if (currentSlide === 0) {
      return slides.length - 1;
    }
    return currentSlide - 1;
  }

  function nextSlide() {
    if (currentSlide === slides.length - 1) {
      return 0;
    }
    return currentSlide + 1;
  }

  return (
    <>
      <div className="carousel_container">
        <div className="carousel_nav_container">
          <Container>
            <Row>
              <Col></Col>
              <Col></Col>
              <Col>
                <div className="control-button-container">
                  <button
                    onClick={() => {
                      let carouselContent =
                        document.getElementsByClassName("carousel-content")[0];
                      let currentSlide =
                        document.getElementsByClassName("current-slide")[0];
                      let lengthafter = 0;
                      let mySlides =
                        document.getElementsByClassName("slide-container");
                      for (
                        let i = mySlides.length - 1;
                        i < mySlides.length;
                        i--
                      ) {
                        let slide = mySlides[i];
                        if (slide.classList.contains("current-slide")) {
                          lengthafter += slide.scrollWidth;
                          break;
                        }
                        lengthafter += slide.scrollWidth;
                      }
                      if (currentSlide.previousSibling) {
                        let position =
                          carouselContent.scrollWidth -
                          (lengthafter +
                            (currentSlide.previousSibling as Element)
                              .scrollWidth /
                              2 +
                            carouselContent.clientWidth / 2);
                        carouselContent.scroll({
                          left: position,
                          behavior: "smooth",
                        });
                      } else {
                        carouselContent.scroll({
                          left: carouselContent.scrollWidth,
                          behavior: "smooth",
                        });
                      }
                      setCurrentSlide(prevSlide());
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      let carouselContent =
                        document.getElementsByClassName("carousel-content")[0];
                      let currentSlide =
                        document.getElementsByClassName("current-slide")[0];
                      let lengthbefore = 0;
                      let mySlides =
                        document.getElementsByClassName("slide-container");
                      for (let i = 0; i < mySlides.length; i++) {
                        let slide = mySlides[i];
                        if (slide.classList.contains("current-slide")) {
                          lengthbefore += slide.scrollWidth;
                          break;
                        }
                        lengthbefore += slide.scrollWidth;
                      }
                      if (currentSlide.nextSibling) {
                        let position =
                          lengthbefore +
                          (currentSlide.nextSibling as Element).scrollWidth / 2;
                        let frame = carouselContent.clientWidth / 2;
                        position = position - frame;
                        carouselContent.scroll({
                          left: position,
                          behavior: "smooth",
                        });
                      } else {
                        carouselContent.scroll({
                          left: 0,
                          behavior: "smooth",
                        });
                      }
                      setCurrentSlide(nextSlide());
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                    </svg>
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="carousel-content">
          {slides.map((img: ImageData, index: any) => {
            if (index === currentSlide) {
              return (
                <div className="slide-container current-slide">
                  <img
                    src={`${process.env.REACT_APP_API_URL}${img.attributes.url}`}
                    alt={getAltLabel(img.attributes)}
                  />
                </div>
              );
            }
            return (
              <div className="slide-container">
                <img
                  src={`${process.env.REACT_APP_API_URL}${img.attributes.url}`}
                  alt={getAltLabel(img.attributes)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Carousel;
