import {createContext, useContext, useReducer, ReactNode} from 'react';

const initialState = {
    fontStatus: false
};

const AllContext = createContext<any>(0);

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'change_fontStatus':
            return {fontStatus: true}
        default: throw new Error();
    }
}

interface AllContextProviderProps {
    children: ReactNode[];
}

const fontsLoaded = () =>{
    new Promise<boolean>((resolve,reject) => {
        var count:number = -5;
        var fontsToCheck:Array<string> = ["AnimalSoul","Desert","Meadow","Mountain","WasteLand"];
            document.fonts.forEach((font)=>{
                var currentFont:string = font.family;
                if(count !== 0) {
                    for(let i=0;i<fontsToCheck.length;i++){
                        if(fontsToCheck[i]){
                            if(currentFont.includes(fontsToCheck[i])){
                                count++;
                                delete fontsToCheck[i];
                                break;
                            }
                        }
                    }
                } else if(count===0){
                    resolve(true);
                    return;
                }
        });
        
        reject(false);
    })   
}

export const checkFontsLoaded = async (): Promise<string> => {
    let result;
    try {
        result = await fontsLoaded();
        result = 'true';
    } catch (err) {
        result = 'false';
    }

    return result;
}

export const AllContextProvider = ({children}:AllContextProviderProps) => {
    const [state, dispatch]:any = useReducer( reducer, initialState)

    return (
        <AllContext.Provider value={[state, dispatch]}>
            {children}
        </AllContext.Provider>
    );
}

export const useAllContext = () => {
    return useContext(AllContext)
}