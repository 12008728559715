import { useQuery } from "react-query";

export const usePagesQuery = () =>
  useQuery({
    queryKey: ["pages"],
    queryFn: fetchPages,
  });

export async function fetchPages() {
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/api/pages?locale=${localStorage.getItem(
      "locale"
    )}`
  );
  return res?.json();
}

export const usePageQuery = (id: string | undefined) =>
  useQuery({
    queryKey: ["page", id],
    queryFn: () => fetchPage(id),
  });

export async function fetchPage(id: string | undefined) {
  const res = await fetch(
    `${
      process.env.REACT_APP_API_URL
    }/api/pages/${id}?locale=${localStorage.getItem(
      "locale"
    )}&populate[0]=blocks&populate[1]=blocks.members,blocks.event,blocks.button,blocks.accordionItem,blocks.image,blocks.teaser_image,blocks.images&populate[2]=blocks.members.image,blocks.event.poster,blocks.event.location&populate[3]=blocks.event.location.accessibility_icons.icon`
  );
  return res?.json();
}
