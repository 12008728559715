import "../../assets/scss/components/checkbox.scss";
import { Checkbox as CheckboxProps } from "../../types/Checkbox";
import { useEffect, useState } from "react";

const Checkbox: React.FC<CheckboxProps> = ({ description, onChange }) => {
  const [checked, setChecked] = useState(false);

  return (
    <>
      <div
        className="tk-wrapper-input-label"
        onClick={() => {
          const newValue = !checked;
          setChecked(newValue);
          onChange(newValue);
        }}
      >
        <div className="tk-input">
          <input
            className="input-checkbox"
            aria-label={description}
            type="checkbox"
            value="Hello"
            checked={checked}
            readOnly
          />
          <span className="checkmark"></span>
        </div>
        <label>{description}</label>
      </div>
    </>
  );
};

export default Checkbox;
