import { Col, Container, Row } from "react-bootstrap";
import { TextProps } from "../../types/Text";
import RichTextRenderer from "../utils/RichTextRenderer";

function Text({ id, children, layout, alignment }: TextProps) {
  return (
    <>
      <Container fluid>
        <Row>
          {(alignment === "right" || layout === "article") && <Col></Col>}
          <Col
            className={`tk-text__content tk-text__content--${alignment} p-0`}
            xs={12}
            md={10}
            lg={layout === "article" ? 8 : 10}
          >
            <div>
              <RichTextRenderer id={id}>{children}</RichTextRenderer>
            </div>
          </Col>
          {(alignment === "left" || layout === "article") && <Col></Col>}
        </Row>
      </Container>
    </>
  );
}

export default Text;
