import React, { useState } from "react";
import { TextInput as TextInputProps } from "../../types/TextInput";

const TextInput: React.FC<TextInputProps> = ({
  placeholder,
  label,
  onChange,
  hideLabel = false,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
    onChange(event);
  };

  return (
    <div className="tk-input-text">
      {!hideLabel && <label>{label}</label>}
      <input
        aria-label={label}
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextInput;
