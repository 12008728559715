import { useQuery } from "react-query";
import { QueryFilter } from "../types/QueryFilter";

export const useAccessibilityIconsQuery = (
  filter?: QueryFilter,
  amount?: number | undefined
) =>
  useQuery({
    queryKey: ["accessibility_icons", filter, amount],
    queryFn: () => fetchAccessibilityIcons(filter, amount),
  });

export async function fetchAccessibilityIcons(
  filter: QueryFilter | undefined,
  amount: number | undefined
) {
  const res = await fetch(
    `${
      process.env.REACT_APP_API_URL
    }/api/accessibility-icons?locale=${localStorage.getItem(
      "locale"
    )}&populate=*&${
      filter
        ? `filters[${filter.field}][${filter.operator}]=${filter.value}`
        : ""
    }&${amount ? `pagination[start]=0&pagination[limit]=${amount}` : ""}`
  );
  return res?.json();
}
