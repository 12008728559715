import { useQuery } from "react-query";
import { WebsiteInformation } from "../types/WebsiteInformation";

export const useWebsiteInformationQuery = () =>
  useQuery({
    queryKey: ["website_information"],
    queryFn: fetchWebsiteInformation,
  });

export async function fetchWebsiteInformation(): Promise<WebsiteInformation> {
  const res = await fetch(
    `${
      process.env.REACT_APP_API_URL
    }/api/website-information?locale=${localStorage.getItem(
      "locale"
    )}&populate=*`
  );
  return res?.json();
}
