import { Col, Container, Row } from "react-bootstrap";
import { ImageTeaserProps } from "../../types/ImageTeaser";
import Button from "./Button";
import { Qtkfonts } from "../utils/QTKkfonts";
import { getAltLabel } from "../../helper/ImageFormatter";
import { useInView } from "react-intersection-observer";

export default function ImageTeaser({
  headline,
  image,
  text,
  button,
  reverse,
}: ImageTeaserProps) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });

  return (
    <>
      <div ref={ref}>
        <Container className="tk-image-teaser" fluid>
          <Row className={reverse ? "flex-row-reverse" : ""}>
            <Col xs={12} md={4} className="tk-image-teaser--image">
              <h3 className="d-block d-md-none mb-3">{Qtkfonts(headline)}</h3>
              {inView &&
                (image.data.attributes.caption ? (
                  <div className="tk-image-teaser__container">
                    <img
                      width={700}
                      height={
                        700 *
                        (image.data.attributes.height /
                          image.data.attributes.width)
                      }
                      src={`${process.env.REACT_APP_API_URL}${image.data.attributes.url}?format=webp&width=700`}
                      alt={getAltLabel(image.data.attributes)}
                    />
                    <p className="tk-image-teaser__caption">
                      {image.data.attributes.caption}
                    </p>
                  </div>
                ) : (
                  <div className="tk-image-teaser__container">
                    <img
                      width={700}
                      height={
                        700 *
                        (image.data.attributes.height /
                          image.data.attributes.width)
                      }
                      src={`${process.env.REACT_APP_API_URL}${image.data.attributes.url}?format=webp&width=700`}
                      alt={getAltLabel(image.data.attributes)}
                    />
                  </div>
                ))}
            </Col>
            <Col xs={12} md={8} className="tk-image-teaser--content">
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h3 className="d-none d-md-block">{Qtkfonts(headline)}</h3>
                <p>{Qtkfonts(text)}</p>
                {button && (
                  <div>
                    <Button
                      variant={button.variant}
                      url={button.url}
                      large={button.large}
                      target_blank={button.target_blank}
                    >
                      {button.children}
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
