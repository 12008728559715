import { useState } from "react";
import TextInput from "../utils/TextInput";
import { Col, Container, Row } from "react-bootstrap";
import Checkbox from "../utils/Checkbox";
import { NewsletterProps } from "../../types/Newsletter";
import { postContact } from "../../api/mailchimp";
import { useTranslation } from "react-i18next";

function Newsletter({ text }: NewsletterProps) {
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/
      );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setError("");
    setSuccessMessage("");

    if (!validateEmail(email)) {
      setError(t("newsletter.validation.valid-email"));
      return;
    }

    if (!isChecked) {
      setError(t("newsletter.validation.terms"));
      return;
    }

    setSuccessMessage(t("newsletter.validation.success"));

    postContact(email);
    setEmail("");
    setIsChecked(false);
  };
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} className="tk-newsletter">
      <Container fluid>
        <Row>
          <Col className="p-0 mb-3">
            <h3 style={{ color: "black" }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            </h3>
          </Col>
        </Row>
        <Row>
          <Col className="p-0">
            <label>{t("newsletter.mail")}</label>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} className="p-0 mb-3 mb-md-0">
            <div className="mb-3 mb-md-0">
              <TextInput
                hideLabel
                label={t("newsletter.mail")}
                placeholder=""
                onChange={(e) => setEmail(e.target.value)}
              ></TextInput>
            </div>
            <div>
              <Checkbox
                description={t("newsletter.terms")}
                onChange={(value) => setIsChecked(value)}
              ></Checkbox>
            </div>
            {error && <div className="tk-form--error">{error}</div>}
            {successMessage && (
              <div className="tk-form--success">{successMessage}</div>
            )}
          </Col>
          <Col xs={12} md={6} className="tk-newsletter-submit">
            <button className="tk-button tk-button--primary" type="submit">
              {t("newsletter.send")}
            </button>
          </Col>
        </Row>
      </Container>
    </form>
  );
}

export default Newsletter;
