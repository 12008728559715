import { useArticlesQuery } from "../../api/articles";
import { ArticlesData } from "../../types/Article";
import Block from "../layout/Block";
import { useState } from "react";
import ButtonContainer from "../page/ButtonContainer";
import { AppStore, useAppStore } from "../../stores/AppStore";
import { useTranslation } from "react-i18next";

function Articles() {
  const [amount, setAmount] = useState(3);
  const { data: articles } = useArticlesQuery();
  const websiteInformation = useAppStore(
    (state: AppStore) => state.websiteInformation
  );
  const { t } = useTranslation();
  return (
    <>
      {websiteInformation &&
        articles?.data.map((article: ArticlesData, index: number) => {
          if (index < amount) {
            return (
              <div key={"article_" + index}>
                <Block
                  id={article.id}
                  type={"page.image-teaser"}
                  data={{
                    headline: article.attributes.title,
                    text: article.attributes.teaser_text,
                    image: article.attributes.teaser_image,
                    button: {
                      url: `${process.env.PUBLIC_URL}/${websiteInformation.data.attributes.blog_page.data.attributes.slug}/${article.attributes.slug}`,
                      variant: "secondary",
                      children: t("readMore"),
                      large: false,
                      target_blank: false,
                    },
                    reverse: index % 2 !== 0,
                  }}
                />
              </div>
            );
          }
        })}
      {articles?.meta?.pagination?.total > amount && (
        <div className="tk-block">
          <ButtonContainer
            button={{
              variant: "secondary",
              onClick: () => {
                setAmount(amount + 3);
              },
              children: t("loadMore"),
              large: true,
            }}
            alignment="right"
          ></ButtonContainer>
        </div>
      )}
    </>
  );
}

export default Articles;
