export async function postContact(email: string) {
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/api/mailchimp/contacts`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    }
  );
  return res?.json();
}
