import { useEffect, useState } from "react";
import { useEventsQuery } from "../../api/events";
import { EventData } from "../../types/Event";
import { EventsProps } from "../../types/Events";
import EventTeaser from "./EventTeaser";
import ButtonContainer from "../page/ButtonContainer";
import { useTranslation } from "react-i18next";

function Events({ type }: EventsProps) {
  const [amount, setAmount] = useState(
    type === "upcoming" || window.innerWidth < 992 ? 2 : 3
  );

  const [isMd, setIsMd] = useState(window.innerWidth >= 992);

  const onResize = () => {
    setIsMd(window.innerWidth >= 992);
  };

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    if (type === "archive") {
      if (!isMd && amount % 2 !== 0) {
        setAmount(amount - 1);
      }
      if (isMd && amount % 3 !== 0) {
        setAmount(amount + 1);
      }
    }
  }, [isMd]);

  const { t } = useTranslation();

  const { data: events } = useEventsQuery(
    {
      field: "date",
      operator: type === "upcoming" ? "$gte" : "$lt",
      value: new Date().toISOString().substring(0, 10),
    },
    undefined,
    type === "upcoming" ? "asc" : "desc"
  );

  const renderEvents = () => {
    if (type === "archive") {
      return (
        <div className="tk-block tk-event-teaser-archive flex-wrap">
          {events?.data.map((event: EventData, index: number) => {
            if (index < amount) {
              return (
                <EventTeaser
                  key={`achive_event_${index}`}
                  type={"small"}
                  reverse={index % 2 === 0 ? false : true}
                  event={{ data: event }}
                  buttonsSeperate
                ></EventTeaser>
              );
            }
          })}
        </div>
      );
    }
    return (
      <div className="tk-event-teaser-upcoming">
        {events?.data.map((event: EventData, index: number) => {
          if (index < amount) {
            return (
              <div key={`upcoming_event_${index}`} className="tk-block">
                <EventTeaser
                  reverse={index % 2 === 0 ? false : true}
                  event={{ data: event }}
                ></EventTeaser>
              </div>
            );
          }
        })}
        {events?.data.length === 0 && (
          <div className="tk-events--empty">
            <p>{t("events.noUpcomingEvents")}</p>
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      {renderEvents()}
      {events?.meta?.pagination?.total > amount && (
        <div className="tk-block">
          <ButtonContainer
            button={{
              variant: "secondary",
              onClick: () => {
                setAmount(isMd && type === "archive" ? amount + 3 : amount + 2);
              },
              children: t("loadMore"),
              large: true,
            }}
            alignment="right"
          ></ButtonContainer>
        </div>
      )}
    </>
  );
}

export default Events;
