/**
 *
 * Based on https://www.npmjs.com/package/react-player
 *
 */
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useCookies } from "react-cookie";
import { MediaPlayerProps } from "../../types/MediaPlayer";
import Checkbox from "../utils/Checkbox";
import Button from "./Button";
import { getAltLabel } from "../../helper/ImageFormatter";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

function MediaPlayer({
  url,
  source,
  teaser_image,
  size = "L",
  cookie_policy_url,
}: MediaPlayerProps) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });
  const cookieIdentifier = `tk_cookie_consent_${source}`;

  const [cookies, setCookie] = useCookies([cookieIdentifier]);
  const [cookieAccepted, setCookieAccepted] = useState(
    cookies[cookieIdentifier]
      ? (cookies[cookieIdentifier] as boolean) == true
      : false
  );
  const [alwaysAcceptChecked, setAlwaysAcceptChecked] = useState(false);
  const [instantPlay, setInstantPlay] = useState(false);

  useEffect(() => {
    if (cookies[cookieIdentifier]) {
      setCookieAccepted(cookies[cookieIdentifier] as boolean);
    }
  }, [cookies]);

  const getCookiePolicyUrl = () => {
    if (cookie_policy_url && cookie_policy_url.trim() != "")
      return cookie_policy_url;

    switch (source) {
      case "Youtube":
        return "https://policies.google.com/privacy";
      case "Vimeo":
        return "https://vimeo.com/privacy";
      case "SoundCloud":
        return "https://soundcloud.com/pages/cookies";
      case "Twitch":
        return "https://www.twitch.tv/p/legal/cookie-notice/";
      case "Facebook":
        return "https://www.facebook.com/privacy/explanation";
      default:
        return "/datenschutz";
    }
  };

  const manageCookieConsent = () => {
    if (alwaysAcceptChecked) {
      setCookie(cookieIdentifier, "true", {
        path: "/",
        expires: new Date(Date.now() + 31536000000),
      });
    }
    setCookieAccepted(true);
    setInstantPlay(true);
  };

  const { t, i18n } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className={`tk-mediaplayer__wrapper--${size}`}>
        <div className="tk-mediaplayer__container" ref={ref}>
          {!url ? (
            <>
              {inView && teaser_image ? (
                <img
                  width={teaser_image.data.attributes.width}
                  height={teaser_image.data.attributes.height}
                  src={`${process.env.REACT_APP_API_URL}${teaser_image.data.attributes.url}`}
                  alt={getAltLabel(teaser_image.data.attributes)}
                />
              ) : null}
              {teaser_image.data.attributes.caption && (
                <p className="tk-mediaplayer__caption">
                  {teaser_image.data.attributes.caption}
                </p>
              )}
            </>
          ) : (
            <div>
              {cookieAccepted && inView ? (
                <ReactPlayer
                  url={url}
                  playing={instantPlay}
                  className="tk-mediaplayer__reactplayer"
                  width="100%"
                  height="100%"
                  controls={true}
                />
              ) : (
                <>
                  <div className="tk-mediaplayer__overlay-container">
                    {inView && teaser_image ? (
                      <img
                        width={teaser_image.data.attributes.width}
                        height={teaser_image.data.attributes.height}
                        src={`${process.env.REACT_APP_API_URL}${teaser_image.data.attributes.url}`}
                        alt={getAltLabel(teaser_image.data.attributes)}
                      />
                    ) : null}
                    <div className="tk-mediaplayer__overlay">
                      <p>{t("mediaPlayer.acceptMedia")}</p>
                      <a
                        className="tk-mediaplayer__link"
                        href={getCookiePolicyUrl()}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {`${t("mediaPlayer.privacyPolicy")}${
                          source ? ` ${t("mediaPlayer.of")} ${source}.` : "."
                        }`}
                      </a>
                      {source && (
                        <Checkbox
                          description={
                            i18n.language === "de"
                              ? `${source} ${t("mediaPlayer.alwaysAccept")}`
                              : `${t("mediaPlayer.alwaysAccept")} ${source}`
                          }
                          onChange={(value) => {
                            setAlwaysAcceptChecked(value);
                          }}
                        />
                      )}
                      <Button variant="secondary" onClick={manageCookieConsent}>
                        {t("mediaPlayer.loadVideo")}
                      </Button>
                    </div>
                  </div>
                  {teaser_image?.data.attributes.caption && (
                    <p className="tk-mediaplayer__caption">
                      {teaser_image.data.attributes.caption}
                    </p>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MediaPlayer;
