import { useEffect } from "react";
import { usePageQuery } from "../../api/pages";
import Block from "./Block";

function Page(props: any) {
  const { data: page } = usePageQuery(props.id);

  return (
    <>
      <div>
        {page?.data.attributes.blocks.map((block: any, index: number) => {
          return (
            <Block
              key={`page_${page.data.id}_block_${index}`}
              id={`page_${page.data.id}_block_${index}`}
              type={block.__component}
              data={block}
            ></Block>
          );
        })}
      </div>
    </>
  );
}

export default Page;
