import { useQuery } from "react-query";

export const useFooterItemsQuery = () =>
  useQuery({
    queryKey: ["footer-items"],
    queryFn: fetchFooterItems,
  });

export async function fetchFooterItems() {
  const res = await fetch(
    `${
      process.env.REACT_APP_API_URL
    }/api/footer-items?locale=${localStorage.getItem("locale")}&populate=page.*`
  );
  return res?.json();
}
