import { useQuery } from "react-query";

export const useNavbarItemsQuery = () =>
  useQuery({
    queryKey: ["navbar-items"],
    queryFn: fetchNavbarItems,
  });

export async function fetchNavbarItems() {
  const res = await fetch(
    `${
      process.env.REACT_APP_API_URL
    }/api/navbar-items?locale=${localStorage.getItem("locale")}&populate=page.*`
  );
  return res?.json();
}

export const useHomepageNavbarItemsQuery = () =>
  useQuery({
    queryKey: ["navbar-items"],
    queryFn: fetchNavbarItems,
  });

export async function fetchHomepageNavbarItem() {
  const res = await fetch(
    `${
      process.env.REACT_APP_API_URL
    }/api/navbar-items?locale=${localStorage.getItem("locale")}&populate=page.*`
  );
  return res?.json();
}
