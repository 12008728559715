import { MapContainer, TileLayer } from "react-leaflet";
import { MapProps } from "../../types/Map";
import "leaflet/dist/leaflet.css";

function Map({ lat, lon, zoom, scrollWheelZoom }: MapProps) {
  return (
    <div className="tk-map">
      <MapContainer
        center={[lat, lon]}
        zoom={zoom ?? 15}
        scrollWheelZoom={scrollWheelZoom ?? false}
        style={{ width: "100%", height: "100%", aspectRatio: 1 / 1 }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </div>
  );
}

export default Map;
