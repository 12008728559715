import { Col, Container, Row } from "react-bootstrap";
import { MapTeaserProps } from "../../types/MapTeaser";
import { useEffect, useState } from "react";
import Map from "../utils/Map";
import Button from "./Button";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

function MapTeaser({ location, reverse, type = "location" }: MapTeaserProps) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });
  const [coordinates, setCoordinates] = useState({ lat: 0, lon: 0 });
  const { t } = useTranslation();
  useEffect(() => {
    fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${location.data.attributes.street}%20${location.data.attributes.street_number},${location.data.attributes.zip_code} ${location.data.attributes.city}`
    )
      .then((response) => response.json())
      .then((response) => {
        setCoordinates({
          lat: Number(response[0].lat),
          lon: Number(response[0].lon),
        });
      });
  }, []);

  return (
    <>
      <div className="tk-map-teaser" ref={ref}>
        {inView && (
          <Container fluid>
            <Row className={reverse ? "flex-row-reverse" : ""}>
              <Col xs={12} md={4} className="tk-map-teaser--map">
                {type !== "address" && (
                  <h3 className="tk-map-headline--mobile d-block d-md-none mb-3">
                    Location
                  </h3>
                )}
                {coordinates.lat !== 0 && coordinates.lon !== 0 && (
                  <Map lat={coordinates.lat} lon={coordinates.lon}></Map>
                )}
              </Col>
              <Col xs={12} md={8} className="tk-map-teaser--content">
                {type === "address" && (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <h3>{t("mapTeaser.address")}</h3>
                    <p>
                      {location.data.attributes.street}&nbsp;
                      {location.data.attributes.street_number},&nbsp;
                      {location.data.attributes.zip_code}&nbsp;
                      {location.data.attributes.city}
                    </p>
                    <h3>Google Maps / Plus Code</h3>
                    <p>{location.data.attributes.google_plus_code}</p>
                  </div>
                )}
                {type === "location" && (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <div className="mb-3 mb-md-0">
                      <h3>Location</h3>
                      {location.data.attributes.title_detail ? (
                        <div>
                          <span>{location.data.attributes.title}</span>
                          <span>{", "}</span>
                          <span>{location.data.attributes.title_detail}</span>
                        </div>
                      ) : (
                        <p>{location.data.attributes.title}</p>
                      )}
                    </div>
                    <div>
                      <Button
                        variant="secondary"
                        large={false}
                        url={`/location/${location.data.attributes.slug}`}
                      >
                        {t("mapTeaser.button")}
                      </Button>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
}
export default MapTeaser;
