import { useQuery } from "react-query";
import { EventProps } from "../types/Event";
import { QueryFilter } from "../types/QueryFilter";

export const useEventsQuery = (
  filter?: QueryFilter,
  amount?: number | undefined,
  sort?: string | undefined
) =>
  useQuery({
    queryKey: ["events", filter, amount],
    queryFn: () => fetchEvents(filter, amount, sort),
  });

export async function fetchEvents(
  filter: QueryFilter | undefined,
  amount: number | undefined,
  sort: string | undefined
) {
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/api/events?locale=${localStorage.getItem(
      "locale"
    )}&populate=*${
      filter
        ? `&filters[${filter.field}][${filter.operator}]=${filter.value}`
        : ""
    }${sort ? `&sort=date:${sort}` : ""}${
      amount ? `&pagination[start]=0&pagination[limit]=${amount}` : ""
    }`
  );
  return res?.json();
}

export const useEventQuery = (id: string | undefined) =>
  useQuery({
    queryKey: ["event", id],
    queryFn: () => fetchEvent(id),
  });

export async function fetchEvent(id: string | undefined): Promise<EventProps> {
  const res = await fetch(
    `${
      process.env.REACT_APP_API_URL
    }/api/events/${id}?locale=${localStorage.getItem(
      "locale"
    )}&populate[0]=teaser_images,trailer,event_info,related_articles,location,event_details&populate[1]=trailer.teaser_image,related_articles.article,teaser_images.images&populate[2]=related_articles.article.teaser_image`
  );
  return res?.json();
}
