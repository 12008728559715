import { RichTextProps } from "../../types/RichText";
import { Qtkfonts } from "./QTKkfonts";

function RichTextRenderer({ id, children }: RichTextProps) {
  return (
    <>
      {children?.map((item: any, itemIndex: number) => {
        if (item.type === "heading") {
          return item.children.map((child: any, childIndex: number) => {
            const HeadlineLevel =
              `h${item.level}` as keyof JSX.IntrinsicElements;
            return (
              <HeadlineLevel key={`rt_${id}_${itemIndex}_${childIndex}`}>
                {Qtkfonts(child.text)}
              </HeadlineLevel>
            );
          });
        }
        if (item.type === "paragraph") {
          return (
            <p key={`rt_${id}_${itemIndex}`}>
              {item.children.map((child: any, index: number) => {
                if (child.type === "link") {
                  return (
                    <a
                      key={index}
                      className="tk-link"
                      href={child.url}
                      style={{ fontWeight: "bold" }}
                    >
                      {child.children.map((child: any) => {
                        return Qtkfonts(child.text);
                      })}
                    </a>
                  );
                }
                if (child.bold) {
                  return (
                    <span style={{ fontWeight: "bold" }}>
                      {Qtkfonts(child.text)}
                    </span>
                  );
                } else if (child.italic) {
                  return (
                    <span style={{ fontStyle: "italic" }}>
                      {Qtkfonts(child.text)}
                    </span>
                  );
                } else {
                  return Qtkfonts(child.text);
                }
              })}
            </p>
          );
        }
      })}
    </>
  );
}

export default RichTextRenderer;
