import { useQuery } from "react-query";
import { ArticleProps } from "../types/Article";
import { QueryFilter } from "../types/QueryFilter";
import { QueryResponse } from "../types/QueryResponse";

export const useArticlesQuery = (amount?: number) =>
  useQuery({
    queryKey: ["articles", amount],
    queryFn: () => fetchArticles(amount ?? undefined),
  });

export async function fetchArticles(amount?: number) {
  const res = await fetch(
    `${
      process.env.REACT_APP_API_URL
    }/api/articles?locale=${localStorage.getItem(
      "locale"
    )}&populate=*&sort=createdAt:desc&${
      amount ? `pagination[start]=0&pagination[limit]=${amount}` : ""
    }`
  );
  return res?.json();
}

export const useArticleQuery = (id: string | undefined) =>
  useQuery({
    queryKey: ["article", id],
    queryFn: () => fetchArticle(id),
  });

export async function fetchArticle(
  id: string | undefined
): Promise<ArticleProps> {
  const res = await fetch(
    `${
      process.env.REACT_APP_API_URL
    }/api/articles/${id}?locale=${localStorage.getItem(
      "locale"
    )}&populate[0]=blocks&populate[1]=blocks.image,teaser_text,teaser_image&populate[2]=blocks.teaser_image,blocks.images`
  );
  return res?.json();
}

export const useArticleFilterQuery = (filter?: QueryFilter, amount?: number) =>
  useQuery({
    queryKey: ["article-filter", filter, amount],
    queryFn: () => fetchArticleBy(filter, amount),
  });

export async function fetchArticleBy(
  filter?: QueryFilter,
  amount?: number
): Promise<QueryResponse> {
  const res = await fetch(
    `${
      process.env.REACT_APP_API_URL
    }/api/articles?locale=${localStorage.getItem("locale")}&${
      filter
        ? `filters[${filter.field}][${filter.operator}]=${filter.value}`
        : ""
    }&${
      amount ? `pagination[start]=0&pagination[limit]=${amount}` : ""
    }&populate[0]=blocks&populate[1]=blocks.image,teaser_text,teaser_image`
  );
  return res?.json();
}
