import { Col, Container, Row } from "react-bootstrap";
import { ImageAttributes } from "../../types/Image";
import { getAltLabel } from "../../helper/ImageFormatter";
import { useInView } from "react-intersection-observer";

function Image({
  name,
  url,
  width,
  height,
  caption,
  alternativeText,
}: ImageAttributes) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });
  return (
    <>
      <div ref={ref}>
        {inView && (
          <Container className="tk-image">
            <Row>
              <Col></Col>
              <Col xs={12} lg={8}>
                {caption ? (
                  <div className="tk-image__container">
                    <img
                      loading="lazy"
                      width={width}
                      height={height}
                      src={`${process.env.REACT_APP_API_URL}${url}`}
                      alt={getAltLabel({
                        name,
                        url,
                        width,
                        height,
                        caption,
                        alternativeText,
                      } as ImageAttributes)}
                    />
                    <p className="tk-image__caption">{caption}</p>
                  </div>
                ) : (
                  <img
                    loading="lazy"
                    width={width}
                    height={height}
                    src={`${process.env.REACT_APP_API_URL}${url}`}
                    alt={getAltLabel({
                      name,
                      url,
                      width,
                      height,
                      caption,
                      alternativeText,
                    } as ImageAttributes)}
                  />
                )}
              </Col>
              <Col></Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
}

export default Image;
