import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./components/navigation/Navbar";
import Footer from "./components/navigation/Footer";
import Page from "./components/layout/Page";
import Article from "./components/layout/Article";
import { usePagesQuery } from "./api/pages";
import { useArticlesQuery } from "./api/articles";
import Sg from "./pages/Sg";
import NotFoundPage from "./pages/NotFoundPage";
import { CookiesProvider } from "react-cookie";
import { useWebsiteInformationQuery } from "./api/website_information";
import { useEventsQuery } from "./api/events";
import Event from "./components/layout/Event";
import { AppStore, useAppStore } from "./stores/AppStore";
import Background from "./components/layout/Background";
import Location from "./components/layout/Location";
import { useLocationsQuery } from "./api/locations";
import LoadingAnimation from "./components/layout/LoadingAnimation";
import { Helmet, HelmetProvider } from "react-helmet-async";
import LogoLarge from "./assets/images/logo-teppichkino.svg";
import LogoSmall from "./assets/images/logo-teppichkino-small.svg";
import appleTouchIcon from "./assets/images/icons/favicons/apple-touch-icon.png";
import favIcon16x16 from "./assets/images/icons/favicons/favicon-16x16.png";
import favIcon32x32 from "./assets/images/icons/favicons/favicon-32x32.png";

function App() {
  const { data: websiteInformation } = useWebsiteInformationQuery();
  const { data: pages } = usePagesQuery();
  const { data: articles } = useArticlesQuery();
  const { data: events } = useEventsQuery();
  const { data: locations } = useLocationsQuery();
  const navbarHeight = useAppStore((state: AppStore) => state.navbarHeight);
  const navbarSticky = useAppStore((state: AppStore) => state.navbarSticky);
  const loading = useAppStore((state: AppStore) => state.loading);
  const setWebsiteInformation = useAppStore(
    (state: AppStore) => state.setWebsiteInformation
  );
  const setLoading = useAppStore((state: AppStore) => state.setLoading);
  const [title, setTitle] = useState("");
  const [slogan, setSlogan] = useState("");

  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    if (websiteInformation && pages && articles && events && locations) {
      setLoading(false);
    }
  }, [websiteInformation, pages, articles, events, locations]);

  useEffect(() => {
    if (websiteInformation) {
      setWebsiteInformation(websiteInformation);
    }
  }, [websiteInformation]);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/admin") {
      window.location.href = `${process.env.REACT_APP_API_URL}/admin`;
    }
    setTitle(
      websiteInformation
        ? websiteInformation.data.attributes.title
        : "Queeres Teppichkino"
    );
    setSlogan(
      websiteInformation ? websiteInformation.data.attributes.slogan : ""
    );
  }, [location, websiteInformation]);

  const pageRoutes =
    pages?.data && Array.isArray(pages.data)
      ? pages.data.map((page: any) => ({
          key: `route_${page.id}`,
          path: `${page.attributes.slug ?? ""}`,
          element: <Page id={`${page.id}`} />,
        }))
      : [];

  const articleRoutes =
    articles?.data && Array.isArray(articles.data)
      ? articles.data.map((article: any) => ({
          key: `articles_${article.id}`,
          path: `${
            websiteInformation?.data.attributes.blog_page.data.attributes.slug
          }/${article.attributes.slug ?? ""}`,
          element: <Article id={`${article.id}`} />,
        }))
      : [];

  const eventRoutes =
    events?.data && Array.isArray(events.data)
      ? events.data.map((event: any) => ({
          key: `event_${event.id}`,
          path: `${
            websiteInformation?.data.attributes.events_page.data.attributes.slug
          }/${event.attributes.slug ?? ""}`,
          element: <Event id={`${event.id}`} />,
        }))
      : [];

  const locationRoutes =
    locations?.data && Array.isArray(locations.data)
      ? locations.data.map((location: any) => ({
          key: `location_${location.id}`,
          path: `location/${location.attributes.slug ?? ""}`,
          element: <Location id={`${location.id}`} />,
        }))
      : [];

  const routes = [
    ...pageRoutes,
    ...articleRoutes,
    ...eventRoutes,
    ...locationRoutes,
    { path: "/sg", element: <Sg /> },
    { path: "*", element: <NotFoundPage /> },
  ];

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{title}</title>
          <link rel="icon" type="image/png" sizes="16x16" href={favIcon16x16} />
          <link rel="icon" type="image/png" sizes="32x32" href={favIcon32x32} />
          <link rel="apple-touch-icon" href={appleTouchIcon} />
          <meta name="description" content={slogan} />
          <meta name="keywords" content="hsd,teppichkino,queer" />
          <meta name="author" content={title} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={slogan} />
          <meta property="og:image" content={LogoLarge} />
          <meta property="og:url" content="https://queeres-teppichkino.com" />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={slogan} />
          <meta name="twitter:image" content={LogoSmall} />
          <meta name="twitter:card" content={LogoLarge} />
          <link rel="preconnect" href={process.env.REACT_APP_API_URL} />
        </Helmet>
      </HelmetProvider>
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <div className="tk-container">
          <Navbar />
          <div
            className="tk-content"
            style={{
              marginTop: navbarSticky ? navbarHeight ?? 0 : 0,
            }}
          >
            {loading && <LoadingAnimation />}
            {!loading && (
              <Routes>
                {routes.map((route, index) => (
                  <Route key={`route_${index}`} {...route} />
                ))}
              </Routes>
            )}
          </div>
          <Footer />
        </div>
      </CookiesProvider>
    </>
  );
}

export default App;
