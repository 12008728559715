export const formatDateToLocaleString = (date: string) => {
  let dateObj = new Date(date);
  return dateObj.toLocaleDateString("de-DE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const formatTimeToLocaleTime = (time: string) => {
  let timeObj = new Date(time);
  return timeObj.toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit",
  });
};
