import { useLocationQuery } from "../../api/locations";
import MapTeaser from "../page/MapTeaser";
import Headline from "../page/Headline";
import Text from "../page/Text";
import { useEffect } from "react";
import Accordion from "../page/Accordion/Accordion";
import { useAccessibilityIconsQuery } from "../../api/accessibility_icons";
import { AccessibilityIcon } from "../../types/AccessibilityIcon";
import { getAltLabel } from "../../helper/ImageFormatter";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function Location(props: any) {
  const { data: location } = useLocationQuery(props.id);
  const { data: accessibilityIcons } = useAccessibilityIconsQuery();

  useEffect(() => {}, [location]);

  const { t } = useTranslation();

  const renderAccessibilityIcons = () => {
    let included: Array<any> = [];
    let excluded: Array<any> = [];
    accessibilityIcons?.data.map((accessibilityIcon: any) => {
      let active = false;
      location?.data.attributes.accessibility_icons.data.map(
        (locationAccessibilityIcon: AccessibilityIcon) => {
          if (accessibilityIcon.id === locationAccessibilityIcon.id) {
            active = true;
          }
        }
      );

      if (active) {
        included.push(
          <div className="tk-accessibility">
            <div className="tk-accessibility__icon">
              <img
                loading="lazy"
                width={accessibilityIcon.attributes.icon.data.attributes.width}
                height={
                  accessibilityIcon.attributes.icon.data.attributes.height
                }
                src={`${process.env.REACT_APP_API_URL}${accessibilityIcon.attributes.icon.data.attributes.url}`}
                alt={getAltLabel(
                  accessibilityIcon.attributes.icon.data.attributes
                )}
              />
            </div>
            <div className="tk-accessibility__description">
              <p style={{ margin: 0 }}>
                {accessibilityIcon.attributes.description}
              </p>
            </div>
          </div>
        );
      } else {
        excluded.push(
          <div className="tk-accessibility">
            <div className="tk-accessibility__icon">
              <img
                loading="lazy"
                width={accessibilityIcon.attributes.icon.data.attributes.width}
                height={
                  accessibilityIcon.attributes.icon.data.attributes.height
                }
                style={{ opacity: 0.3 }}
                src={`${process.env.REACT_APP_API_URL}${accessibilityIcon.attributes.icon.data.attributes.url}`}
                alt={getAltLabel(
                  accessibilityIcon.attributes.icon.data.attributes
                )}
              />
            </div>
            <div className="tk-accessibility__description">
              <p style={{ margin: 0, opacity: 0.5 }}>
                {accessibilityIcon.attributes.description}
              </p>
            </div>
          </div>
        );
      }
    });
    return (
      <div>
        {included}
        {excluded}
      </div>
    );
  };
  return (
    <>
      {location && (
        <div>
          <div className="tk-block">
            {location.data.attributes.title_detail ? (
              <Container fluid>
                <Row>
                  <Col
                    className={`tk-text__content tk-text__content--left p-0`}
                    xs={12}
                    md={9}
                  >
                    <div>
                      <h1
                        dangerouslySetInnerHTML={{
                          __html:
                            location.data.attributes.title +
                            ", <br />" +
                            location.data.attributes.title_detail,
                        }}
                      ></h1>
                    </div>
                  </Col>
                  <Col></Col>
                </Row>
              </Container>
            ) : (
              <Headline alignment="left" level="h1">
                {location.data.attributes.title}
              </Headline>
            )}
          </div>
          <div className="tk-block">
            <Text id={`${location?.data.id}_description`} alignment="right">
              {location?.data.attributes.description}
            </Text>
          </div>
          {location && (
            <div className="tk-block">
              <MapTeaser location={location} type="address"></MapTeaser>
            </div>
          )}
          {location.data.attributes.directions && (
            <div className="tk-block">
              <Accordion
                title={location.data.attributes.directions.title}
                accordionItem={
                  location.data.attributes.directions.accordionItem
                }
              ></Accordion>
            </div>
          )}
          <div className="tk-block">
            <Headline alignment="left" level="h2">
              {t("accessibility")}
            </Headline>
          </div>
          {location && accessibilityIcons && renderAccessibilityIcons()}
        </div>
      )}
    </>
  );
}

export default Location;
