import { Col, Container, Row } from "react-bootstrap";
import { HeadlineProps } from "../../types/Headline";
import { Qtkfonts } from "../utils/QTKkfonts";

function Headline({ children, level, alignment }: HeadlineProps) {
  const HeadlineLevel = `${level}` as keyof JSX.IntrinsicElements;
  return (
    <>
      <Container fluid>
        <Row>
          {alignment === "right" && <Col></Col>}
          <Col
            className={`tk-text__content tk-text__content--${alignment} p-0`}
            xs={12}
            md={9}
          >
            <div>
              <HeadlineLevel>{Qtkfonts(children)}</HeadlineLevel>
            </div>
          </Col>
          {alignment === "left" && <Col></Col>}
        </Row>
      </Container>
    </>
  );
}

export default Headline;
