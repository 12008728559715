import { NavLink, Link } from "react-router-dom";
import { useFooterItemsQuery } from "../../api/footer";
import { useSocialItemsQuery } from "../../api/socials";
import Newsletter from "../formular/Newsletter";
import Logo from "../../assets/images/logo-teppichkino.svg";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const Footer = () => {
  const { data: footerItems } = useFooterItemsQuery();
  const { data: socialItems } = useSocialItemsQuery();
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: any) => {
    setSelectedLocale(lng);
    i18n.changeLanguage(lng);
    localStorage.setItem("locale", lng);
    window.location.reload();
  };

  const [selectedLocale, setSelectedLocale] = useState(
    localStorage.getItem("locale")
  );

  function changeLocale(lng: any) {
    lng === "en" ? changeLanguage("en") : changeLanguage("de");
  }

  return (
    <Container className="tk-footer__container" fluid>
      <Row>
        <Col className="p-0">
          <Container className="tk-footer__newsletter-container" fluid>
            <Row className="tk-footer__newsletter">
              <Col
                xs={12}
                md={3}
                lg={4}
                className="tk-footer__newsletter--left"
              >
                <img
                  loading="lazy"
                  alt="Queeres Teppichkino Logo"
                  width={300}
                  height={158}
                  src={Logo}
                />
              </Col>
              <Col
                xs={12}
                md={9}
                lg={8}
                className="tk-footer__newsletter--right px-0"
              >
                <Newsletter text={t("newsletter.footer-teaser")} />
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Row>
              <Col className="tk-footer">
                <div className="tk-footer__social">
                  {socialItems &&
                    socialItems.data?.map((item: any) => {
                      return (
                        <div
                          key={`footer_social_item_${item.id}`}
                          className="tk-link__container"
                        >
                          <NavLink
                            to={item.attributes.url}
                            className="tk-link"
                            target="_blank"
                          >
                            {item.attributes.platform}
                          </NavLink>
                        </div>
                      );
                    })}
                </div>
                <div className="tk-footer__menu">
                  {footerItems &&
                    footerItems.data?.map((item: any) => {
                      if (item.attributes.page.data) {
                        return (
                          <div
                            className="tk-link__container"
                            key={`footer_social_item_${item.id}`}
                          >
                            <NavLink
                              key={`footer_item_${item.id}`}
                              to={`/${item.attributes.page.data.attributes.slug}`}
                              className="tk-link"
                              onClick={() => {
                                window.scroll(0, 0);
                              }}
                            >
                              {item.attributes.title}
                            </NavLink>
                          </div>
                        );
                      }
                    })}
                  <div className="tk-select tk-language-toggle">
                    <select
                      aria-label="Language Toggle"
                      value={selectedLocale ?? "de"}
                      onChange={(e) => changeLocale(e.target.value)}
                    >
                      <option value="de">{t("general.de")}</option>
                      <option value="en">{t("general.en")}</option>
                    </select>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
