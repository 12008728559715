import { useArticleQuery, useArticleFilterQuery } from "../../api/articles";
import ButtonContainer from "../page/ButtonContainer";
import Headline from "../page/Headline";
import ImageTeaser from "../page/ImageTeaser";
import Block from "./Block";
import { ArticlesData } from "../../types/Article";
import { AppStore, useAppStore } from "../../stores/AppStore";
import { useTranslation } from "react-i18next";

function Article(props: any) {
  const { data: article } = useArticleQuery(props.id);
  const { data: nextArticle } = useArticleFilterQuery(
    {
      field: "createdAt",
      operator: "$lt",
      value: article
        ? new Date(article.data.attributes.createdAt).toISOString()
        : new Date().toISOString(),
    },
    1
  );

  const websiteInformation = useAppStore(
    (state: AppStore) => state.websiteInformation
  );
  const { t } = useTranslation();

  return (
    <>
      <div className="tk-article">
        {article && websiteInformation && (
          <Block
            id={article.data.id}
            type={"page.image-teaser"}
            data={{
              headline: article.data.attributes.title,
              text: article.data.attributes.teaser_text,
              image: article.data.attributes.teaser_image,
            }}
          />
        )}

        {article?.data.attributes.blocks.map((block: any, index: number) => {
          return (
            <Block
              key={`article_${article.data.id}_block_${index}`}
              id={`article_${article.data.id}_block_${index}`}
              type={block.__component}
              layout="article"
              noBorders={article?.data.attributes.no_borders}
              data={block}
            ></Block>
          );
        })}
      </div>
      {article?.data.attributes.no_borders === true && (
        <div className="tk-block" />
      )}
      {nextArticle?.data.map((article: ArticlesData, index: number) => {
        return (
          <div>
            <div className="tk-block">
              <Headline alignment="right" level="h2">
                {t("nextEntry")}
              </Headline>
            </div>
            <div className="tk-block">
              <ImageTeaser
                headline={article.attributes.title}
                text={article.attributes.teaser_text}
                image={article.attributes.teaser_image}
                button={{
                  url: article.attributes.slug,
                  children: t("readMore"),
                  variant: "secondary",
                }}
              />
            </div>
          </div>
        );
      })}
      <div className="tk-block">
        <ButtonContainer
          button={{
            variant: "primary",
            url: `/${websiteInformation?.data.attributes.blog_page.data.attributes.slug}`,
            children: t("backToArchive"),
            large: true,
          }}
          alignment="left"
        ></ButtonContainer>
      </div>
    </>
  );
}

export default Article;
