import { QuoteProps } from "../../types/Quote";

function Quote({ children, author }: QuoteProps) {
  return (
    <>
      <div className="tk-quote-container">
        <p className="tk-quote">
          {"»"}
          {children}
          {"«"}
          <span className="tk-quote--author">
            {" — "}
            {author}
          </span>
        </p>
      </div>
    </>
  );
}

export default Quote;
