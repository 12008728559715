import { useQuery } from "react-query";

export const useSocialItemsQuery = () =>
  useQuery({
    queryKey: ["social-items"],
    queryFn: fetchSocialItems,
  });

export async function fetchSocialItems() {
  const res = await fetch(
    `${
      process.env.REACT_APP_API_URL
    }/api/social-items?locale=${localStorage.getItem("locale")}&populate=page.*`
  );
  return res?.json();
}
