import { Col, Container, Row } from "react-bootstrap";
import { BlockProps } from "../../types/Block";
import { BlogImage } from "../../types/BlogImage";
import { ButtonContainerProps } from "../../types/ButtonContainer";
import { EventsProps } from "../../types/Events";
import { HeadlineProps } from "../../types/Headline";
import { ImageTeaserProps } from "../../types/ImageTeaser";
import { MediaPlayerProps } from "../../types/MediaPlayer";
import { QuoteProps } from "../../types/Quote";
import { TextProps } from "../../types/Text";
import { AccordionProps } from "../../types/Accordion";
import Articles from "../blog/Articles";
import Image from "../blog/Image";
import Newsletter from "../formular/Newsletter";
import ButtonContainer from "../page/ButtonContainer";
import Headline from "../page/Headline";
import ImageTeaser from "../page/ImageTeaser";
import MediaPlayer from "../page/MediaPlayer";
import Quote from "../page/Quote";
import Text from "../page/Text";
import Accordion from "../page/Accordion/Accordion";
import Events from "../program/Events";
import { NewsletterProps } from "../../types/Newsletter";
import { EventTeaserProps } from "../../types/EventTeaser";
import EventTeaser from "../program/EventTeaser";
import { CarouselProps } from "../../types/Carousel";
import Carousel from "../page/Carousel";
import Team from "../team/Team";
import { TeamProps } from "../../types/Team";

function Block({ id, type, layout, noBorders, data }: BlockProps) {
  const renderBlock = () => {
    if (type === "blog.articles") {
      return <Articles />;
    }
    if (type === "event.events") {
      return (
        <Events
          id={id}
          headline={(data as EventsProps).headline}
          type={(data as EventsProps).type}
          amount={(data as EventsProps).amount}
        />
      );
    }

    return (
      <div className={noBorders === true ? "no-border" : "tk-block"}>
        {type === "page.headline" && (
          <Headline
            level={(data as HeadlineProps).level}
            alignment={(data as HeadlineProps).alignment}
          >
            {(data as HeadlineProps).children}
          </Headline>
        )}
        {type === "page.quote" && (
          <Quote author={(data as QuoteProps).author}>
            {(data as QuoteProps).children}
          </Quote>
        )}
        {type === "page.button-container" && (
          <ButtonContainer
            button={(data as ButtonContainerProps).button}
            alignment={(data as ButtonContainerProps).alignment}
          ></ButtonContainer>
        )}
        {type === "page.image-teaser" && (
          <ImageTeaser
            headline={(data as ImageTeaserProps).headline}
            text={(data as ImageTeaserProps).text}
            image={(data as ImageTeaserProps).image}
            button={(data as ImageTeaserProps).button}
            reverse={(data as ImageTeaserProps).reverse}
          />
        )}
        {type === "blog.image" && (
          <Image
            name={(data as BlogImage).image.data?.attributes.name}
            url={(data as BlogImage).image.data?.attributes.url}
            width={(data as BlogImage).image.data?.attributes.width}
            height={(data as BlogImage).image.data?.attributes.height}
            caption={(data as BlogImage).image.data?.attributes.caption}
            alternativeText={
              (data as BlogImage).image.data?.attributes.alternativeText
            }
          ></Image>
        )}
        {type === "page.text" && (
          <Text
            id={id}
            alignment={(data as TextProps).alignment}
            layout={layout}
          >
            {(data as TextProps).children}
          </Text>
        )}
        {type === "page.accordion" && (
          <Accordion
            accordionItem={(data as AccordionProps).accordionItem}
            title={(data as AccordionProps).title}
          />
        )}
        {type === "formular.newsletter" && (
          <Container fluid>
            <Row>
              <Col xs={0} md={2} className="p-0"></Col>
              <Col
                xs={12}
                md={10}
                lg={8}
                className="tk-newsletter-container p-0"
              >
                <Newsletter text={(data as NewsletterProps).text}></Newsletter>
              </Col>
            </Row>
          </Container>
        )}
        {type === "event.event-teaser" && (
          <EventTeaser
            type={(data as EventTeaserProps).type}
            reverse={(data as EventTeaserProps).reverse}
            event={(data as EventTeaserProps).event}
          />
        )}
        {type === "page.media-player" && (
          <MediaPlayer
            id={(data as MediaPlayerProps).id}
            url={(data as MediaPlayerProps).url}
            source={(data as MediaPlayerProps).source}
            teaser_image={(data as MediaPlayerProps).teaser_image}
            size={(data as MediaPlayerProps).size}
            cookie_policy_url={(data as MediaPlayerProps).cookie_policy_url}
          />
        )}
        {type === "page.carousel" && (
          <Carousel
            id={(data as CarouselProps).id}
            images={(data as CarouselProps).images}
          />
        )}
        {type === "team.team" && <Team members={(data as TeamProps).members} />}
      </div>
    );
  };
  return (
    <>
      <div>{renderBlock()}</div>
    </>
  );
}

export default Block;
