import { useQuery } from "react-query";
import { QueryFilter } from "../types/QueryFilter";
import { LocationProps } from "../types/Location";

export const useLocationsQuery = (
  filter?: QueryFilter,
  amount?: number | undefined
) =>
  useQuery({
    queryKey: ["locations", filter, amount],
    queryFn: () => fetchLocations(filter, amount),
  });

export async function fetchLocations(
  filter: QueryFilter | undefined,
  amount: number | undefined
) {
  const res = await fetch(
    `${
      process.env.REACT_APP_API_URL
    }/api/locations?locale=${localStorage.getItem("locale")}&populate=*&${
      filter
        ? `filters[${filter.field}][${filter.operator}]=${filter.value}`
        : ""
    }&${amount ? `pagination[start]=0&pagination[limit]=${amount}` : ""}`
  );
  return res?.json();
}

export const useLocationQuery = (id: string | undefined) =>
  useQuery({
    queryKey: ["location", id],
    queryFn: () => fetchLocation(id),
  });

export async function fetchLocation(
  id: string | undefined
): Promise<LocationProps> {
  const res = await fetch(
    `${
      process.env.REACT_APP_API_URL
    }/api/locations/${id}?locale=${localStorage.getItem(
      "locale"
    )}&populate[0]=directions,accessibility_icons&populate[1]=directions.accordionItem,accessibility_icons.icon`
  );
  return res?.json();
}
