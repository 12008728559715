import Button from "../components/page/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Qtkfonts } from "../components/utils/QTKkfonts";
import MediaPlayer from "../components/page/MediaPlayer";
import { MediaPlayerProps } from "../types/MediaPlayer";
import { useTranslation } from "react-i18next";


function Sg() {
  const mediaPlayerData1: MediaPlayerProps = {
    id: "10",
    url: "https://soundcloud.com/tommisch/you-got-me-flying?si=10c101285cbe4907841d33f9f48571bb&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing",
    source: "SoundCloud",
    teaser_image: {
      data: {
        id: "10",
        attributes: {
          name: "bluejean_plakat_rgb_1400px_1_61599147b4.webp",
          url: "/uploads/large_bluejean_plakat_rgb_1400px_1_61599147b4.webp",
          width: 0,
          height: 0,
        },
      },
    },
    cookie_policy_url: "",
  };

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng:any) => {
    i18n.changeLanguage(lng);
  };

  function changeLocale() {
    i18n.language === 'en' ? changeLanguage('de') : changeLanguage('en');
  }

  const mediaPlayerData2: MediaPlayerProps = {
    id: "11",
    url: "https://vimeo.com/watchale/sidewalk",
    source: "Vimeo",
    teaser_image: {
      data: {
        id: "10",
        attributes: {
          name: "bluejean_plakat_rgb_1400px_1_61599147b4.webp",
          url: "/uploads/large_bluejean_plakat_rgb_1400px_1_61599147b4.webp",
          width: 0,
          height: 0,
        },
      },
    },
    cookie_policy_url: "",
  };

  const mediaPlayerData3: MediaPlayerProps = {
    id: "12",
    url: "https://www.youtube.com/watch?v=bK6ldnjE3Y0",
    source: "Youtube",
    teaser_image: {
      data: {
        id: "10",
        attributes: {
          name: "bluejean_plakat_rgb_1400px_1_61599147b4.webp",
          url: "/uploads/large_bluejean_plakat_rgb_1400px_1_61599147b4.webp",
          width: 0,
          height: 0,
        },
      },
    },
    cookie_policy_url: "",
  };

  return (
    <>
      <Container>
        <Row>
          <Col className="p-4">
            <p>{Qtkfonts("Queeres Teppich Kino")}</p>
            <h1>{t("notFound.oops")}</h1>
            <h2>Headline 2</h2>
            <h3>Headline 3</h3>
            <p style={{ fontWeight: 300 }}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
            <Button
                variant="secondary"
                large={false}
                onClick={() => changeLocale()}
              >
                {t("changeLng")}
              </Button>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
            <p style={{ fontWeight: 700 }}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
            <p style={{ fontWeight: 300 }}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
            <p style={{ fontWeight: 700 }}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
            <p style={{ fontWeight: 300 }}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
            <p style={{ fontWeight: 700 }}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
            <p style={{ fontWeight: 300 }}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
            <p style={{ fontWeight: 700 }}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
            <p style={{ fontWeight: 300 }}>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.
            </p>
            <p>
              {Qtkfonts(
                "Queeres Teppichkino, Queeren Teppichkino, Queerem Teppichkino, Queeren Teppichkinos"
              )}
            </p>
            <a className="tk-link">Anchor Link</a>
            <div className="tk-button-container">
              <Button
                variant="primary"
                url="home"
                large={false}
                target_blank={false}
              >
                Zum Film
              </Button>
              <Button
                variant="secondary"
                url="home"
                large={false}
                target_blank={false}
              >
                Zum Film
              </Button>
            </div>
            <div className="tk-button-container">
              <Button
                variant="primary"
                url="home"
                large={false}
                target_blank={false}
              >
                Zum Film
              </Button>
              <Button
                variant="secondary"
                url="home"
                large={false}
                target_blank={false}
              >
                Zum Film
              </Button>
            </div>
            <div style={{ margin: "20px 0" }}>
              <a className="tk-link">Media Player</a>
            </div>
            <Container>
              <Row style={{ margin: "20px 0" }}>
                <Col>
                  <MediaPlayer
                    id={mediaPlayerData1.id}
                    url={mediaPlayerData1.url}
                    source={mediaPlayerData1.source}
                    teaser_image={mediaPlayerData1.teaser_image}
                    cookie_policy_url={mediaPlayerData1.cookie_policy_url}
                  />
                </Col>
                <Col>
                  <MediaPlayer
                    id={mediaPlayerData2.id}
                    url={mediaPlayerData2.url}
                    source={mediaPlayerData2.source}
                    teaser_image={mediaPlayerData2.teaser_image}
                    cookie_policy_url={mediaPlayerData2.cookie_policy_url}
                  />
                </Col>
              </Row>
              <Row>
                <MediaPlayer
                  id={mediaPlayerData3.id}
                  url={mediaPlayerData3.url}
                  source={mediaPlayerData3.source}
                  teaser_image={mediaPlayerData3.teaser_image}
                  cookie_policy_url={mediaPlayerData3.cookie_policy_url}
                />
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Sg;
