import { create } from "zustand";
import { WebsiteInformation } from "../types/WebsiteInformation";

export interface AppStore {
  navbarSticky: boolean;
  navbarHeight: number;
  mobileMenuToggled: boolean;
  loading: boolean;
  websiteInformation: WebsiteInformation | undefined;
  setNavbarSticky: (value: boolean) => void;
  setMobileMenuToggled: (value: boolean) => void;
  setLoading: (value: boolean) => void;
  setNavbarHeight: (value: number) => void;
  setWebsiteInformation: (value: WebsiteInformation) => void;
}

export const useAppStore = create<AppStore>((set) => ({
  navbarSticky: false,
  navbarHeight: 0,
  loading: false,
  mobileMenuToggled: false,
  websiteInformation: undefined,
  setNavbarSticky: (value: boolean) => set(() => ({ navbarSticky: value })),
  setLoading: (value: boolean) => set(() => ({ loading: value })),
  setMobileMenuToggled: (value: boolean) =>
    set(() => ({ mobileMenuToggled: value })),
  setNavbarHeight: (value: number) => set(() => ({ navbarHeight: value })),
  setWebsiteInformation: (value: WebsiteInformation) =>
    set(() => ({ websiteInformation: value })),
}));
