import { MemberProps } from "../../types/Member";

export default function Member({ name, description, image }: MemberProps) {
  return (
    <>
      <div className="tk-member">
        <img
          width={510}
          height={510}
          src={`${process.env.REACT_APP_API_URL}${image.data.attributes.url}?format=webp&width=510`}
          alt={image.data.attributes.alternativeText ?? "Team Member"}
        />
        <h3>{name}</h3>
        <p>{description}</p>
      </div>
    </>
  );
}
