import { Col, Container, Row } from "react-bootstrap";
import { EventTeaserProps } from "../../types/EventTeaser";
import Button from "../page/Button";
import { AppStore, useAppStore } from "../../stores/AppStore";
import {
  formatDateToLocaleString,
  formatTimeToLocaleTime,
} from "../../helper/DateTimeFormatter";
import { AccessibilityIcon } from "../../types/AccessibilityIcon";
import { getAltLabel } from "../../helper/ImageFormatter";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

function EventTeaser({
  type,
  reverse,
  event,
  buttonsSeperate,
}: EventTeaserProps) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    fallbackInView: true,
  });
  const websiteInformation = useAppStore(
    (state: AppStore) => state.websiteInformation
  );

  const { t } = useTranslation();

  const renderTime = () => {
    if (type !== "small") {
      return (
        <div className="tk-event-teaser__time p-0">
          <p>
            {t("eventTeaser.start")}{" "}
            {formatTimeToLocaleTime(event?.data.attributes.date)}{" "}
            {t("eventTeaser.oclock")}
          </p>
        </div>
      );
    }
  };

  const renderLocation = () => {
    if (type !== "small") {
      if (event?.data.attributes.location.data != null) {
        return (
          <div className="tk-event-teaser__location mb-3 p-0">
            <p>{event?.data.attributes.location?.data.attributes.title}</p>
            {event?.data.attributes.location?.data.attributes.title_detail && (
              <p>
                {event?.data.attributes.location?.data.attributes.title_detail}
              </p>
            )}
          </div>
        );
      } else {
        return <div className="tk-event-teaser__location mb-3 p-0" />;
      }
    }
  };

  const renderAccessibilityIcons = () => {
    let icons: Array<any> = [];
    event?.data.attributes.location?.data.attributes.accessibility_icons?.data.map(
      (accessibilityIcon: AccessibilityIcon) => {
        icons.push(
          <img
            loading="lazy"
            width={25}
            height={25}
            src={`${process.env.REACT_APP_API_URL}${accessibilityIcon.attributes.icon.data.attributes.url}?format=webp&width=50`}
            alt={getAltLabel(accessibilityIcon.attributes.icon.data.attributes)}
          />
        );
      }
    );
    return <div className="tk-event-teaser__iconds mb-sm-3 p-0">{icons}</div>;
  };

  return (
    <>
      <div className="tk-event-teaser__container" ref={ref}>
        {inView && (
          <div className="tk-event-teaser__wrapper">
            <Container
              className={`tk-event-teaser ${
                type ? `tk-event-teaser--${type}` : ""
              }`}
              fluid
            >
              <Row
                className={`content-wrapper ${
                  reverse ? "flex-row-reverse" : ""
                }`}
              >
                <Col
                  xs={12}
                  sm={type !== "small" ? 6 : 12}
                  lg={type !== "small" ? 5 : 12}
                  className="inline-flex p-0"
                >
                  <div className="tk-event-heading--mobile mb-3 d-block d-sm-none">
                    <div className="p-0">
                      <h3 className="tk-event-teaser__title">
                        {event?.data.attributes.title}
                      </h3>
                    </div>
                    <div className="d-flex justify-content-between w-100">
                      <p className="tk-event-teaser__producer">
                        {t("eventTeaser.from")}{" "}
                        {event?.data.attributes.producer}
                      </p>
                      <p className="tk-event-teaser__date">
                        {formatDateToLocaleString(event?.data.attributes.date)}
                      </p>
                    </div>
                  </div>
                  <div className="tk-event-teaser__poster">
                    <div className="tk-event-teaser__poster-container">
                      <img
                        width={600}
                        height={
                          600 *
                          (event?.data.attributes.poster.data.attributes
                            .height /
                            event?.data.attributes.poster.data.attributes.width)
                        }
                        src={`${process.env.REACT_APP_API_URL}${event?.data.attributes.poster.data.attributes.url}?format=webp&width=600`}
                        alt={getAltLabel(
                          event?.data.attributes.poster.data.attributes
                        )}
                      />
                      {event?.data.attributes.poster.data.attributes
                        .caption && (
                        <p className="tk-event-teaser__poster-caption">
                          {
                            event?.data.attributes.poster.data.attributes
                              .caption
                          }
                        </p>
                      )}
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={type !== "small" ? 6 : 12}
                  lg={type !== "small" ? 7 : 12}
                  className="p-0"
                >
                  <div className="tk-event-teaser__information">
                    <div className="tk-event-teaser__information--text d-none d-sm-block">
                      <div className="mb-1 mb-md-3 p-0">
                        <p className="tk-event-teaser__date">
                          {formatDateToLocaleString(
                            event?.data.attributes.date
                          )}
                        </p>
                      </div>
                      <div className="p-0">
                        <h3 className="tk-event-teaser__title">
                          {event?.data.attributes.title}
                        </h3>
                      </div>
                      <div>
                        <p className="tk-event-teaser__producer mb-1 mb-md-3">
                          {t("eventTeaser.from")}{" "}
                          {event?.data.attributes.producer}
                        </p>
                      </div>
                      {renderTime()}
                      {renderLocation()}
                      {type !== "small" &&
                        event?.data.attributes.location.data != null &&
                        renderAccessibilityIcons()}
                    </div>
                    {!buttonsSeperate && (
                      <div>
                        <div className="mt-3 mt-sm-0 mb-3 mb-sm-2 p-0">
                          <Button
                            variant={type === "focus" ? "primary" : "secondary"}
                            url={`${websiteInformation?.data.attributes.events_page.data.attributes.slug}/${event?.data.attributes.slug}`}
                          >
                            {t("eventTeaser.toMovie")}
                          </Button>
                        </div>
                        {type === "focus" && (
                          <div className="tk-event-teaser__program-link p-0">
                            <Button
                              variant="secondary"
                              url={`${websiteInformation?.data.attributes.events_page.data.attributes.slug}`}
                            >
                              {t("eventTeaser.toProgram")}
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
            {buttonsSeperate && (
              <div className="buttons">
                <div className="mt-3 mt-sm-0 mb-3 mb-sm-2 p-0">
                  <Button
                    variant={type === "focus" ? "primary" : "secondary"}
                    url={`${websiteInformation?.data.attributes.events_page.data.attributes.slug}/${event?.data.attributes.slug}`}
                  >
                    {t("eventTeaser.toMovie")}
                  </Button>
                </div>
                {type === "focus" && (
                  <div className="tk-event-teaser__program-link p-0">
                    <Button
                      variant="secondary"
                      url={`${websiteInformation?.data.attributes.events_page.data.attributes.slug}`}
                    >
                      {t("eventTeaser.toProgram")}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default EventTeaser;
