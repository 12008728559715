import { NavLink } from "react-router-dom";
import LogoLarge from "../../assets/images/logo-teppichkino.svg";
import LogoSmall from "../../assets/images/logo-teppichkino-small.svg";
import { useNavbarItemsQuery } from "../../api/navbar";
import { useEffect, useRef, useState } from "react";
import { AppStore, useAppStore } from "../../stores/AppStore";

function Navbar() {
  const mobileMenuToggled = useAppStore(
    (state: AppStore) => state.mobileMenuToggled
  );
  const setMobileMenuToggled = useAppStore(
    (state: AppStore) => state.setMobileMenuToggled
  );

  const navbarSticky = useAppStore((state: AppStore) => state.navbarSticky);
  const setNavbarSticky = useAppStore(
    (state: AppStore) => state.setNavbarSticky
  );

  const setNavbarHeight = useAppStore(
    (state: AppStore) => state.setNavbarHeight
  );

  const websiteInformation = useAppStore(
    (state: AppStore) => state.websiteInformation
  );

  const [slogan, setSlogan] = useState("");

  const { data: navbarItems } = useNavbarItemsQuery();

  const navbarRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (websiteInformation) {
      setSlogan(
        websiteInformation ? websiteInformation.data.attributes.slogan : ""
      );
    }
  }, [websiteInformation]);

  useEffect(() => {
    updateNavbarHeight();
    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const updateNavbarHeight = () => {
    if (!navbarRef.current) return;
    const resizeObserver = new ResizeObserver((event) => {
      setNavbarHeight(event[0].contentRect.height);
    });
    resizeObserver.observe(navbarRef.current);
    return () => resizeObserver.disconnect();
  };

  const onScroll = () => {
    if (window.scrollY > 10 && !navbarSticky) {
      setNavbarSticky(true);
    } else {
      setNavbarSticky(false);
    }
  };

  const onResize = () => {
    if (window.innerWidth > 900) {
      setMobileMenuToggled(false);
      document.body.classList.remove("mobile-menu-toggled");
    }
  };

  const toggleMobileMenu = (toggled: boolean) => {
    setMobileMenuToggled(toggled);
    if (toggled) {
      document.body.classList.add("mobile-menu-toggled");
    } else {
      document.body.classList.remove("mobile-menu-toggled");
    }
  };

  const menu = (
    <div className="tk-navbar__menu">
      <div className="tk-navbar__slogan">
        <p>{slogan}</p>
      </div>
      <div className="tk-navbar__links">
        {navbarItems &&
          navbarItems.data?.map((item: any) => {
            return (
              item.attributes.active &&
              item.attributes.page.data && (
                <div key={`navbar_item_${item.id}`} className="tk-navbar__link">
                  <NavLink
                    to={`/${item.attributes.page.data.attributes.slug}`}
                    className="tk-link"
                    onClick={() => {
                      window.scroll(0, 0);
                      toggleMobileMenu(false);
                    }}
                  >
                    {item.attributes.title}
                  </NavLink>
                </div>
              )
            );
          })}
      </div>
    </div>
  );

  return (
    <>
      <div
        ref={navbarRef}
        className={
          mobileMenuToggled
            ? `tk-navbar__container mobile--toggled ${
                navbarSticky ? "sticky" : ""
              }`
            : `tk-navbar__container ${navbarSticky ? "sticky" : ""}`
        }
      >
        <div className="tk-navbar">
          <div className="tk-navbar--left">
            <NavLink
              className="tk-navbar__logo"
              to="/"
              aria-label="Homepage"
              onClick={() => {
                window.scroll(0, 0);
                toggleMobileMenu(false);
              }}
            >
              <div
                className="tk-navbar__logo--small"
                style={{
                  backgroundImage: `url(${LogoSmall})`,
                }}
              ></div>
              <div
                className="tk-navbar__logo--large"
                style={{
                  backgroundImage: `url(${LogoLarge})`,
                }}
              ></div>
            </NavLink>
          </div>
          <div className="tk-navbar--right">
            <div className="tk-navbar__toggle">
              <div
                className="tk-navbar__toggle-icon"
                onClick={() => toggleMobileMenu(!mobileMenuToggled)}
              >
                <div className="tk-navbar__toggle-stroke"></div>
                <div className="tk-navbar__toggle-stroke"></div>
                <div className="tk-navbar__toggle-stroke"></div>
                <div className="tk-navbar__toggle-stroke"></div>
              </div>
            </div>
            {menu}
          </div>
        </div>
        {mobileMenuToggled && (
          <div className="tk-navbar__menu--mobile">{menu}</div>
        )}
      </div>
    </>
  );
}

export default Navbar;
