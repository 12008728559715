import { Container, Col, Row } from "react-bootstrap";
import { useEventQuery } from "../../api/events";
import RichTextRenderer from "../utils/RichTextRenderer";
import MediaPlayer from "../page/MediaPlayer";
import Headline from "../page/Headline";
import ImageTeaser from "../page/ImageTeaser";
import { ArticleTeaserProps } from "../../types/ArticleTeaser";
import {
  formatDateToLocaleString,
  formatTimeToLocaleTime,
} from "../../helper/DateTimeFormatter";
import MapTeaser from "../page/MapTeaser";
import { EventInfo } from "../../types/EventInfo";
import { AppStore, useAppStore } from "../../stores/AppStore";
import Accordion from "../page/Accordion/Accordion";
import { useTranslation } from "react-i18next";
import Carousel from "../page/Carousel";

function Event(props: any) {
  const { data: event } = useEventQuery(props.id);
  const websiteInformation = useAppStore(
    (state: AppStore) => state.websiteInformation
  );
  const { t } = useTranslation();

  return (
    <>
      {event && (
        <div>
          <div className="tk-event__mediaplayer-container tk-block">
            <MediaPlayer
              id={event.data.attributes.trailer.id}
              url={event.data.attributes.trailer.url}
              source={event.data.attributes.trailer.source}
              teaser_image={event.data.attributes.trailer.teaser_image}
              size="L"
            />
          </div>
          <Container className="tk-block" fluid>
            <Row>
              <Col md={8} className="tk-event__title">
                <h1> {`${event.data.attributes.title} –`}</h1>
                <h1 className="highlighted">
                  {event.data.attributes.producer}
                </h1>
              </Col>
              <Col className="tk-event__title-date-place-container">
                <h5>{formatDateToLocaleString(event.data.attributes.date)}</h5>
                <h5>
                  {formatTimeToLocaleTime(event.data.attributes.date)} Uhr
                </h5>
                {event.data.attributes.location.data !== null && (
                  <h5>
                    {event.data.attributes.location.data.attributes.title}
                  </h5>
                )}
              </Col>
            </Row>
          </Container>
          {event.data.attributes.event_info &&
            event.data.attributes.event_info.length > 0 && (
              <div className="tk-block tk-event__info-container">
                {event.data.attributes.event_info.map((info: EventInfo) => {
                  return <h5 key={info.id}>{info.info_item}</h5>;
                })}
              </div>
            )}
          <Container className="tk-block" fluid>
            <Row>
              <Col xs={0} md={3} />
              <Col xs={12} md={9} className="tk-event__description-container">
                <RichTextRenderer id={`${props.id}_event_description`}>
                  {event.data.attributes.description}
                </RichTextRenderer>
              </Col>
            </Row>
          </Container>
          {event.data.attributes.event_details &&
            event.data.attributes.event_details.length > 0 && (
              <div className="tk-block">
                <Accordion
                  accordionItem={event.data.attributes.event_details}
                />
              </div>
            )}
          {event.data.attributes.teaser_images && (
            <div className="tk-block">
              <Carousel
                id={event.data.attributes.teaser_images.id}
                images={event.data.attributes.teaser_images.images}
              />
            </div>
          )}
          {event.data.attributes.location.data !== null && (
            <div>
              <div className="tk-block">
                <Headline alignment="right" level="h1">
                  {t("findUs")}
                </Headline>
              </div>
              <div className="tk-block">
                <MapTeaser
                  location={event.data.attributes.location}
                ></MapTeaser>
              </div>
            </div>
          )}
          {event.data.attributes.related_articles &&
            event.data.attributes.related_articles.length > 0 && (
              <div>
                <div className="tk-block">
                  <Headline alignment="left" level="h1">
                    {t("blogEntries")}
                  </Headline>
                </div>
                {event.data.attributes.related_articles.map(
                  (articleData: ArticleTeaserProps) => {
                    return (
                      <>
                        {articleData.article.data !== null && (
                          <div className="tk-block">
                            <ImageTeaser
                              headline={
                                articleData.article.data.attributes.title
                              }
                              text={
                                articleData.article.data.attributes.teaser_text
                              }
                              image={
                                articleData.article.data.attributes.teaser_image
                              }
                              button={{
                                url: `/${websiteInformation?.data.attributes.blog_page.data.attributes.slug}/${articleData.article.data.attributes.slug}`,
                                children: t("readMore"),
                                variant: "secondary",
                              }}
                              reverse={articleData.reverse}
                            />
                          </div>
                        )}
                      </>
                    );
                  }
                )}
              </div>
            )}
        </div>
      )}
    </>
  );
}

export default Event;
