import { ButtonProps } from "../../types/Button";

function Button({
  children,
  variant,
  url,
  large,
  target_blank,
  onClick,
}: ButtonProps) {
  const renderButton = () => {
    if (url) {
      return (
        <a
          href={url}
          target={target_blank ? "_blank" : "_self"}
          className={`tk-button tk-button--${variant} ${large ? "large" : ""}`}
        >
          {children}
        </a>
      );
    }
    return (
      <div
        onClick={onClick ? onClick : undefined}
        className={`tk-button tk-button--${variant} ${large ? "large" : ""}`}
      >
        {children}
      </div>
    );
  };

  return <>{renderButton()}</>;
}

export default Button;
