import { Col, Container, Row } from "react-bootstrap";
import { AccordionProps } from "../../../types/Accordion";
import AccordionItem from "./AccordionItem";
import Headline from "../Headline";

function Accordion({ title, accordionItem }: AccordionProps) {
  return (
    <>
      <div className="accordion-container">
        {title && (
          <div className="accordion-headline">
            <Headline level="h2" alignment="right">
              {title}
            </Headline>
          </div>
        )}
        <Container fluid>
          <Row>
            <Col xs={1} md={3} className="p-0" />
            <Col xs={12} md={9} className="p-0">
              <div className="accordion accordion-flush" id="accordionExample">
                {accordionItem.map((question: any, index: number) => (
                  <AccordionItem
                    key={`accordion_item_${index}`}
                    id={question.id}
                    question={question.question}
                    answer={question.answer}
                  ></AccordionItem>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Accordion;
