import reactStringReplace from "react-string-replace";
import { useAllContext, checkFontsLoaded } from "../../context/changeContext";

function teppichKinoReplace(newText: string) {
  return reactStringReplace(newText, "Teppichkino", (match, i) => (
    <>
      <span className="animalsoul">T</span>
      <span className="desert">E</span>
      <span className="meadow">PP</span>
      <span className="wasteland">I</span>
      <span className="desert">C</span>
      <span className="mountain">H</span>
      <span className="meadow">K</span>
      <span className="animalsoul">I</span>
      <span className="wasteland">N</span>
      <span className="meadow">O</span>
    </>
  ));
}

function queeresReplace(newText: string) {
  return reactStringReplace(newText, "Queere", (match, i) => (
    <>
      <span className="meadow">Q</span>
      <span className="desert">U</span>
      <span className="wasteland">EE</span>
      <span className="mountain">R</span>
      <span className="desert">E</span>
    </>
  ));
}

function queeresVariationReplace(newText: string) {
  var switchText = reactStringReplace(
    newText,
    "Queeres Teppichkino",
    (match, i) => (
      <>
        {queeresReplace("Queere")}
        <span className="desert">S </span>
        {teppichKinoReplace("Teppichkino")}
      </>
    )
  );

  switchText = reactStringReplace(
    switchText,
    "Queeren Teppichkinos",
    (match, i) => (
      <>
        {queeresReplace("Queere")}
        <span className="desert">N </span>
        {teppichKinoReplace("Teppichkino")}
        <span className="desert">S</span>
      </>
    )
  );

  switchText = reactStringReplace(
    switchText,
    "Queeren Teppichkino",
    (match, i) => (
      <>
        {queeresReplace("Queere")}
        <span className="desert">N </span>
        {teppichKinoReplace("Teppichkino")}
      </>
    )
  );

  switchText = reactStringReplace(
    switchText,
    "Queerem Teppichkino",
    (match, i) => (
      <>
        {queeresReplace("Queere")}
        <span className="desert">M </span>
        {teppichKinoReplace("Teppichkino")}
      </>
    )
  );

  switchText = reactStringReplace(
    switchText,
    "Queere Teppichkino",
    (match, i) => (
      <>
        {queeresReplace("Queere ")}
        {teppichKinoReplace("Teppichkino")}
      </>
    )
  );

  return switchText;
}

export function Qtkfonts(text: string) {
  return <>{queeresVariationReplace(text)}</>;
}
