import { Col, Container, Row } from "react-bootstrap";
import { ButtonProps } from "../../types/Button";
import { ButtonContainerProps } from "../../types/ButtonContainer";
import Button from "./Button";

function ButtonContainer({ button, alignment }: ButtonContainerProps) {
  return (
    <Container fluid>
      <Row className={alignment === "right" ? "flex-row-reverse" : ""}>
        <Col xs={12} md={8} className="tk-button-container p-0">
          <div>
            <Button
              variant={button.variant}
              large={button.large ?? undefined}
              url={button.url ?? undefined}
              target_blank={button.target_blank ?? undefined}
              onClick={button.onClick ?? undefined}
            >
              {button.children}
            </Button>
          </div>
        </Col>
        <Col xs={0} md={4}></Col>
      </Row>
    </Container>
  );
}

export default ButtonContainer;
