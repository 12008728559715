import { AppStore, useAppStore } from "../stores/AppStore";
import Button from "../components/page/Button";
import { Col, Container, Row } from "react-bootstrap";
import { Qtkfonts } from "../components/utils/QTKkfonts";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const websiteInformation = useAppStore(
    (state: AppStore) => state.websiteInformation
  );
  const { t, i18n } = useTranslation();

  return (
    <Container className="tk-not-found">
      <Row>
        <Col>
          <h1>
            <b>{t("notFound.oops")}</b> {t("notFound.nothingHere")}
          </h1>
          <div>
            <p>
              {t("notFound.message1")}
              {i18n.language === "de"
                ? Qtkfonts("Queere Teppichkino")
                : Qtkfonts("Queeres Teppichkino")}
              {t("notFound.message2")}
              <br />
              {t("notFound.visitOurPage")}
            </p>
            <div className="tk-not-found__buttons">
              <Button
                variant="primary"
                large={false}
                url={`${websiteInformation?.data.attributes.events_page.data.attributes.slug}`}
              >
                {t("notFound.program")}
              </Button>

              <Button
                variant="secondary"
                large={false}
                url={`${websiteInformation?.data.attributes.blog_page.data.attributes.slug}`}
              >
                {t("notFound.blog")}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFoundPage;
