import { useEffect } from "react";
import { MemberProps } from "../../types/Member";
import { TeamProps } from "../../types/Team";
import Member from "./Member";

export default function Team({ members }: TeamProps) {
  return (
    <>
      <div className="tk-team">
        {members?.map((member: MemberProps) => {
          return (
            <Member
              name={member.name}
              description={member.description}
              image={member.image}
            ></Member>
          );
        })}
      </div>
    </>
  );
}
